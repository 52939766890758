import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import {
  Title,
  Input,
  Button,
  Field,
  Control,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Percentage Calculator",
    link: "/percentage-calculator/",
  },
]
const seeAlsoArray = [
  "/reverse-percentages-calculator",
  "/capsule-volume-and-area-calculator",
  "/cone-volume-and-area-calculator",
  "/conical-frustum-volume-and-area-calculator",
  "/cube-volume-and-area-calculator",
  "/cylinder-volume-and-area-calculator",
  "/rectangular-volume-and-area-calculator",
  "/sphere-volume-and-area-calculator",
  "/square-pyramid-volume-and-area-calculator",
]

function PercentageCalculator(props) {
  let [resultOne, setresultOne] = useState("")
  let valueOne = useRef("")
  let valueToo = useRef("")

  let valuethree = useRef("")
  let valueFoor = useRef("")
  let [resultTwo, setresultTwo] = useState("")

  let valueFive = useRef("")
  let valueSix = useRef("")
  let [resultThree, setresultThree] = useState("")

  function calculateOne(e) {
    let value1 = valueOne.current.value
    let value2 = valueToo.current.value
    if (value1 === "" && value2 === "") {
    } else {
      value1 = Number(value1)
      value2 = Number(value2)

      let result = (value1 / 100) * value2
      setresultOne(result)
    }
  }

  function calculateTwo(e) {
    let value1 = valuethree.current.value
    let value2 = valueFoor.current.value
    if (value1 === "" && value2 === "") {
    } else {
      value1 = Number(value1)
      value2 = Number(value2)

      let result = (value1 / value2) * 100
      setresultTwo(result)
    }
  }

  function calculateThree(e) {
    let value1 = valueFive.current.value
    let value2 = valueSix.current.value
    if (value1 === "" && value2 === "") {
    } else {
      value1 = Number(value1)
      value2 = Number(value2)

      let result = ((value2 - value1) / value1) * 100
      setresultThree(result)
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Percentage Calculator"
        description="Simple percentage calculation, a tool to calculate percentages. What is the number in % of this number or number is what percent of another number? and What is the percentage increase/decrease from the number to a number?"
        keywords={[
          "percentage calculate,is what percent of ? %,percent calc,What is % of ? %, percentages ,percentage increase/decrease from to, percentage, tool to calculate percentages, What is % of ? %, is what percent of ? %, What is the percentage increase/decrease from to .",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Percentage Calculator</Title>
        <FlexDiv maxWidth="500px">
          <Field>
            <Control>
              <StaticButton>What is</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input radius="0" type="number" ref={valueOne} />
            </Control>
            <Control>
              <StaticButton bottomLeftRadius="0" TopLeftRadius="0">
                {" "}
                % Of
              </StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input radius="0" type="number" ref={valueToo} />
            </Control>
            <Control>
              <StaticButton
                bottomRightRadius="4px"
                topRightRadius="4px"
                bottomLeftRadius="0"
                TopLeftRadius="0"
              >
                ?
              </StaticButton>
            </Control>
          </Field>

          <Field>
            <Control>
              <Button
                margin="0"
                className="staticRight mb-2"
                type="button"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                onClick={calculateOne}
              >
                Calculate
              </Button>
            </Control>
            <Control className="expandedWidth">
              <Input radius="0" type="number" value={resultOne} readOnly />
            </Control>
            <Control>
              <StaticButton
                bottomRightRadius="4px"
                topRightRadius="4px"
                bottomLeftRadius="0"
                TopLeftRadius="0"
              >
                &nbsp; %
              </StaticButton>
            </Control>
          </Field>

          <br />

          <Field>
            <Control className="expandedWidth">
              <Input type="number" className="borderedLeft" ref={valuethree} />
            </Control>
            <Control>
              <StaticButton bottomLeftRadius="0" TopLeftRadius="0">
                {" "}
                Is what percent Of
              </StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input radius="0" type="number" ref={valueFoor} />
            </Control>
            <Control>
              <StaticButton
                bottomRightRadius="4px"
                topRightRadius="4px"
                bottomLeftRadius="0"
                TopLeftRadius="0"
              >
                ?
              </StaticButton>
            </Control>
          </Field>

          <Field>
            <Control>
              <Button
                margin="0"
                className="staticRight mb-2"
                type="button"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                onClick={calculateTwo}
              >
                Calculate
              </Button>
            </Control>
            <Control className="expandedWidth">
              <Input radius="0" type="number" value={resultTwo} readOnly />
            </Control>
            <Control>
              <StaticButton
                bottomRightRadius="4px"
                topRightRadius="4px"
                bottomLeftRadius="0"
                TopLeftRadius="0"
              >
                &nbsp; %
              </StaticButton>
            </Control>
          </Field>
          <br />
          <strong className="mb-1">
            What is the percentage increase/decrease
          </strong>
          <Field>
            <Control>
              <StaticButton>From</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input radius="0" type="number" ref={valueFive} />
            </Control>
            <Control>
              <StaticButton bottomLeftRadius="0" TopLeftRadius="0">
                {" "}
                To
              </StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input radius="0" type="number" ref={valueSix} />
            </Control>
            <Control>
              <StaticButton
                bottomRightRadius="4px"
                topRightRadius="4px"
                bottomLeftRadius="0"
                TopLeftRadius="0"
              >
                ?
              </StaticButton>
            </Control>
          </Field>

          <Field>
            <Control>
              <Button
                margin="0"
                className="staticRight mb-2"
                type="button"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                onClick={calculateThree}
              >
                Calculate
              </Button>
            </Control>
            <Control className="expandedWidth">
              <Input radius="0" type="number" value={resultThree} readOnly />
            </Control>
            <Control>
              <StaticButton
                bottomRightRadius="4px"
                topRightRadius="4px"
                bottomLeftRadius="0"
                TopLeftRadius="0"
              >
                &nbsp; %
              </StaticButton>
            </Control>
          </Field>
        </FlexDiv>

        <br />
        <h3>Calculate Percentage</h3>
        <p>
          calculate the percentage variation between two values, the percentage
          formula can be printed in different forms like above.
          <br />A tool to calculate percentages. What is the number in % of this
          number or number is what percent of another number? and What is the
          percentage increase/decrease from the number to a number?
          <br />
          more tools about percentage click{" "}
          <a href="http://calcpercentage.com/" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default PercentageCalculator
